<template>
    <b-card no-body>
        <b-card-body>
            <div class="d-flex justify-content-end  flex-wrap">

                <!-- filter -->
                <b-form-group

                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="pagination.filter"
                            type="search"
                            :placeholder="$t('Type to Search')"
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!pagination.filter"
                                @click="pagination.filter = ''"
                            >
                                {{ $t("Clear") }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-card-body>

        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="pagination.perPage"
            :current-page="pagination.currentPage"
            :items="employees"
            :fields="fields"
            :filter="pagination.filter"
        >
            <template #cell(avatar)="data">
                <b-avatar :src="data.item.full_path"/>
            </template>

            <template #cell(belediye)="data">
                <p>{{ data.item.belediyeler[0].il.name }}, {{ data.item.belediyeler[0].name }} </p>
            </template>
            <template #cell(status)="data">
                <b-form-checkbox
                    :checked="data.item.is_active"
                    name="check-button"
                    switch
                    inline
                    @change="changeUserStatus(data.item.is_active,data.item.id)"
                >
                </b-form-checkbox>
            </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
                :label="$t('Per Page')"
                label-cols="8"
                label-align="left"
                label-size="md"
                label-for="perPageSelect"
                class="text-nowrap mb-md-0 mr-1"
            >
                <b-form-select
                    id="perPageSelect"
                    v-model="pagination.perPage"
                    size="sm"
                    inline
                    :options="pagination.pageOptions"
                />
            </b-form-group>

            <!-- pagination -->
            <div>
                <b-pagination
                    v-model="pagination.currentPage"
                    :total-rows="employees.length"
                    :per-page="pagination.perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                >
                    <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                    </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BAvatar,
    BBadge, BButton,
    BCard, BCardBody,
    BFormCheckbox,
    BFormGroup, BFormInput,
    BFormSelect,
    BInputGroup, BInputGroupAppend,
    BPagination,
    BTable
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "list",
    components: {
        BFormCheckbox,
        BCard,
        BCardCode,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
    },
    data() {
        return {
            fields: [
                {key: 'avatar', label: this.$t('Image'),},
                {key: 'first_name', label: this.$t('First Name'), sortable: false},
                {key: 'last_name', label: this.$t('Last Name'), sortable: false},
                {key: 'email', label: this.$t('Email'), sortable: false},
                {key: 'phone', label: this.$t('Phone'), sortable: false},
                {key: 'belediye', label: this.$t('Phone'), sortable: false},
                {key: 'status', label: this.$t('Status'), sortable: false},
            ],
            pagination: {
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                filter: '',
            },
        };
    },
    computed: {
        employees() {
            return this.$store.state.employees.employees;
        },
    },
    methods: {
        getEmployees(){
            this.$store.dispatch('employees/getEmployees')
                .then(res => {
                    console.log(this.employees);
                })
                .catch(err => {
                });
        },
        changeUserStatus(is_active, id) {
            this.$store.dispatch('employees/updateEmployeeStatus', {new_status: is_active, admin_id: id})
                .then(res => {
                    this.getEmployees();
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: res.data.message,
                                variant: 'success',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })

                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        }
    },
    created() {
        this.getEmployees();
    }
}
</script>

<style lang="scss">

</style>
